/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserLinkedAccounts } from './UserLinkedAccounts';
import {
    UserLinkedAccountsFromJSON,
    UserLinkedAccountsFromJSONTyped,
    UserLinkedAccountsToJSON,
} from './UserLinkedAccounts';
import type { UserStatus } from './UserStatus';
import {
    UserStatusFromJSON,
    UserStatusFromJSONTyped,
    UserStatusToJSON,
} from './UserStatus';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    emailVerified?: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    image?: string;
    /**
     * 
     * @type {UserStatus}
     * @memberof User
     */
    status?: UserStatus;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    cashbackPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    rrmThreshold?: number;
    /**
     * 
     * @type {UserLinkedAccounts}
     * @memberof User
     */
    linkedAccounts?: UserLinkedAccounts;
    /**
     * This user's referral code which can be used to make referrals.
     * @type {string}
     * @memberof User
     */
    referralCode?: string;
    /**
     * Whether this user is subscribed to Gondola emails or not.
     * @type {boolean}
     * @memberof User
     */
    subscribed: boolean;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): value is User {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('subscribed' in value) || value['subscribed'] === undefined) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'email': json['email'] == null ? undefined : json['email'],
        'emailVerified': json['email_verified'] == null ? undefined : (new Date(json['email_verified'])),
        'image': json['image'] == null ? undefined : json['image'],
        'status': json['status'] == null ? undefined : UserStatusFromJSON(json['status']),
        'cashbackPercentage': json['cashback_percentage'] == null ? undefined : json['cashback_percentage'],
        'rrmThreshold': json['rrm_threshold'] == null ? undefined : json['rrm_threshold'],
        'linkedAccounts': json['linked_accounts'] == null ? undefined : UserLinkedAccountsFromJSON(json['linked_accounts']),
        'referralCode': json['referral_code'] == null ? undefined : json['referral_code'],
        'subscribed': json['subscribed'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'email': value['email'],
        'email_verified': value['emailVerified'] == null ? undefined : ((value['emailVerified']).toISOString()),
        'image': value['image'],
        'status': UserStatusToJSON(value['status']),
        'cashback_percentage': value['cashbackPercentage'],
        'rrm_threshold': value['rrmThreshold'],
        'linked_accounts': UserLinkedAccountsToJSON(value['linkedAccounts']),
        'referral_code': value['referralCode'],
        'subscribed': value['subscribed'],
    };
}

